<template>
  <b-card @click="openTask(task.task_id, task.status)" role="button">
    <b-row m-0 p-0>
      <b-col cols="2">
        <img :src="'/icons/'+task.icon" style="max-width:60px;width:100%;" />
      </b-col>
      <b-col cols="8">
        <b-card-title>{{ task.title }}</b-card-title>
        <b-card-text>{{ task.description }}</b-card-text>
      </b-col>
      <b-col class="text-center">
        <img v-if="task.status == 1" src="/icons/check.png" style="max-width:50px;width:100%;" />
        <p v-if="task.status == 1" class="text-success">
          Completed
        </p>
        <img v-if="task.status == 0" src="/icons/close.svg" style="max-width:60px;width:100%;" />
        <p v-if="task.status == 0" class="text-danger">
          Open
        </p>
        <img v-if="task.status == 2" src="/icons/in_progress.svg" style="max-width:50px;width:100%;" />
        <p v-if="task.status == 2" class="text-primary">
          In Progress
        </p>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BCardText, BCardTitle } from 'bootstrap-vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BCardTitle,
  },
  props: ['task'],
  name: "CookpitTask",
  methods: {
    openTask(task, status){
      if(status==0 || status===2) {
        if (task == 1) {
          this.$router.push('/cookpit/task/add-company')
        }
      }else{
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Task already completed.',
            icon: 'SuccessIcon',
            variant: 'success',
          },
        });
      }
    }
  }
}
</script>

<style scoped>

</style>
